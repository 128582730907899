import React, {
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useList } from 'react-use'
import { v7 as uuidv7 } from 'uuid'

import { AttachClipIcon } from '@/components/icons/AttachClipIcon'
import { SendIcon } from '@/components/icons/SendIcon'
import { AlertDialog } from '@/components/ui/alertDialog/AlertDialog'
import { Button } from '@/components/ui/button/Button'
import { TextAreaWithMentions } from '@/components/ui/input-with-mentions/TextAreaWithMentions'
import { useApi } from '@/contexts/ApiProvider'
import { ChatMessageInputFileList } from '@/features/task/components/chat/ChatMessageInputFileList'
import { useUsers } from '@/features/task/hooks/useUsers'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { useFileUpload } from '@/lib/hooks/useFileUpload'
import { isImageFile } from '@/lib/utils'

interface IChatMessageInputProperties {
  onSendMessage: (data: { message: string; fileIds?: string[] }) => void
  disabled?: boolean
}

export interface FileListItem {
  id: string
  file: File
}

const MAX_FILE_SIZE = 300 * 1024 * 1024

const useChatMessageInput = ({
  onSendMessage,
}: IChatMessageInputProperties) => {
  const api = useApi()
  const [message, setMessage] = useState<string>('')
  const inputReference = useRef<HTMLTextAreaElement>(null)
  const [
    fileList,
    { clear: clearFileList, push: pushFileList, removeAt: removeAtFileList },
  ] = useList<FileListItem>([])

  const [upload, filesState, setFilesState] = useFileUpload(
    api.getUploadFileParameters
  )

  const isUploadPending = useMemo(
    () => fileList.some((fileListItem) => !filesState[fileListItem.id]?.done),
    [fileList, filesState]
  )

  const isSendingMessageDisabled = useMemo(
    () => (!message && fileList.length === 0) || isUploadPending,
    [message, fileList, isUploadPending]
  )

  const handleSendClick = useCallback(() => {
    if (!isSendingMessageDisabled) {
      const fileIds = fileList
        .map((fileListItem) => filesState[fileListItem.id]?.data?.id)
        .filter((id): id is string => !!id)

      onSendMessage({
        fileIds,
        message: message.trim(),
      })
      setMessage('')
      clearFileList()
      setFilesState({})
      if (inputReference.current) {
        inputReference.current.style.height = 'auto'
      }
    }
  }, [
    clearFileList,
    filesState,
    isSendingMessageDisabled,
    message,
    onSendMessage,
    setFilesState,
    fileList,
  ])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) => {
      if (!isSendingMessageDisabled) {
        if (event.key === 'Escape') {
          event.preventDefault()
          setMessage('')
        }
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          handleSendClick()
        }
      }
    },
    [isSendingMessageDisabled, handleSendClick]
  )

  return {
    fileList,
    filesState,
    handleKeyPress,
    handleSendClick,
    isSendingMessageDisabled,
    isUploadPending,
    message,
    pushFileList,
    removeAtFileList,
    setFilesState,
    setMessage,
    upload,
  }
}

const createFileListItem = (file: File): FileListItem => ({
  file,
  id: uuidv7(),
})

export const ChatMessageInput = ({
  disabled,
  onSendMessage,
}: IChatMessageInputProperties) => {
  const { users } = useUsers()
  const {
    setFalse: closeFileSizeAlert,
    setTrue: showFileSizeAlert,
    value: isFileSizeAlertOpen,
  } = useBoolean(false)

  const {
    fileList,
    filesState,
    handleKeyPress,
    handleSendClick,
    isSendingMessageDisabled,
    isUploadPending,
    message,
    pushFileList,
    removeAtFileList,
    setFilesState,
    setMessage,
    upload,
  } = useChatMessageInput({
    onSendMessage,
  })

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (!files || files.length === 0) return

      for (const file of files) {
        if (file.size > MAX_FILE_SIZE) {
          showFileSizeAlert()
          event.target.value = ''
          return
        }
      }

      const fileListItems = [...files].map((file) => createFileListItem(file))
      pushFileList(...fileListItems)
      upload(fileListItems)
      event.target.value = ''
    },
    [pushFileList, showFileSizeAlert, upload]
  )

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const items = event.clipboardData?.items
      if (!items) return

      const imageItems = [...items].filter((item) => isImageFile(item))

      for (const item of imageItems) {
        const file = item.getAsFile()
        if (file) {
          const fileListItem = createFileListItem(file)
          pushFileList(fileListItem)
          upload([fileListItem])
        }
      }
    },
    [pushFileList, upload]
  )

  const onDelete = useCallback(
    (fileId: string) => {
      const currentIndex = fileList.findIndex((file) => file.id === fileId)
      if (currentIndex !== -1) {
        removeAtFileList(currentIndex)
      }
    },
    [fileList, removeAtFileList]
  )

  return (
    <>
      <div className="rounded-lg bg-white py-3">
        {fileList.length > 0 && (
          <ChatMessageInputFileList
            fileList={fileList}
            filesState={filesState}
            onDelete={onDelete}
            setFilesState={setFilesState}
            onKeyPress={handleKeyPress}
          />
        )}

        <div className="px-3">
          <TextAreaWithMentions
            mentions={users.map((user) => ({
              avatarUrl: user.avatarUrl,
              display: user.name,
              id: user.id,
            }))}
            className="caret-sky-500"
            placeholder="Write a message..."
            onChange={setMessage}
            onKeyDown={handleKeyPress}
            value={message}
            onPaste={handlePaste}
          />

          <div className="flex flex-row items-center justify-end gap-3">
            <Button
              asChild
              variant="naked"
              size="sm"
              className="h-8 w-8 cursor-pointer p-0"
            >
              <label>
                <input
                  data-testid="input-file-upload"
                  type="file"
                  className="hidden"
                  onChange={handleFileUpload}
                  multiple
                  disabled={isUploadPending}
                />
                <AttachClipIcon />
              </label>
            </Button>
            <Button
              data-testid="button-send-message"
              onClick={handleSendClick}
              size="sm"
              className="h-8 w-8 p-0"
              type="submit"
              variant="solid"
              disabled={isSendingMessageDisabled || disabled}
            >
              <SendIcon />
            </Button>
          </div>
        </div>
      </div>
      <AlertDialog
        title="File size is too large"
        actionText="OK"
        description="Maximum file size supported is 300MB."
        isDialogOpen={isFileSizeAlertOpen}
        onActionClick={closeFileSizeAlert}
      />
    </>
  )
}

ChatMessageInput.displayName = 'ChatMessageInput'
