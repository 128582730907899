import { FC, useCallback } from 'react'
import {
  DisplayTransformFunc,
  Mention,
  MentionProps,
  MentionsInput,
  MentionsInputStyle,
  OnChangeHandlerFunc,
  SuggestionDataItem,
} from 'react-mentions'

import { Avatar } from '@/components/ui/avatar/Avatar'

interface SuggestionItem extends SuggestionDataItem {
  avatarUrl?: string
}

interface TextAreaWithMentionsProperties
  extends Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'onChange' | 'onSelect' | 'onBlur'
  > {
  mentions: MentionProps['data']
  onChange: (value: string) => void
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void

  onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void
  value: string
}

const mentionsInputStyle: MentionsInputStyle = {
  '&multiLine': {
    highlighter: {
      border: 1,
    },
    input: {
      border: 0,
      outline: 0,
      padding: 0,
    },
  },
  suggestions: {
    backgroundColor: 'transparent',
    item: {
      '&focused': {
        backgroundColor: 'rgb(7 89 133)',
        borderRadius: 9,
        color: 'rgb(245 245 244)',
      },
    },
  },
}

const mentionStyle: React.CSSProperties = {
  color: 'rgb(177, 144, 101)',
  position: 'relative',
  zIndex: 2,
}

const displayTransform: DisplayTransformFunc = (_, display) => `@${display} `

const customSuggestionContainer = (children: React.ReactNode) => (
  <div
    className="max-h-[70vh] min-w-64 overflow-y-auto rounded-lg bg-mono-paper p-1 shadow-just-shadow"
    data-testid="mention-menu"
  >
    <h4 className="my-1 block px-1 text-xs leading-4 font-medium text-mono-ink-subtle">
      People
    </h4>
    {children}
  </div>
)

const UserSuggestion: FC<SuggestionItem> = (userSuggestion) => (
  <div
    key={userSuggestion.id}
    className="z-50 flex cursor-pointer items-center gap-1 rounded-md p-1 hover:border-film-normal"
  >
    <Avatar
      src={userSuggestion.avatarUrl}
      className="h-6 w-6 rounded-full"
      boringAvatarSeed={`${userSuggestion.id}`}
    />
    <span>{userSuggestion.display}</span>
  </div>
)
UserSuggestion.displayName = 'UserSuggestion'

/*
 * We are suppressing the default error console log because React has deprecated the use of defaultProps in function components.
 * This is a temporary workaround to avoid cluttering the console with warnings until the codebase is updated to remove the usage of defaultProps.
 * https://github.com/signavio/react-mentions/issues/709
 */
const originalError = console.error
console.error = (...argument) => {
  const errorMessage = argument[0]
  if (
    typeof errorMessage === 'string' &&
    errorMessage.includes(
      'defaultProps will be removed from function components'
    )
  ) {
    const stackTrace = new Error('defaultProps').stack

    if (stackTrace?.includes('mentions')) {
      return
    }
  }
  originalError(...argument)
}

export const TextAreaWithMentions = ({
  mentions,
  onChange,
  value,
  ...properties
}: TextAreaWithMentionsProperties) => {
  const handleChange: OnChangeHandlerFunc = useCallback(
    (event) => {
      onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <MentionsInput
      data-testid="chat-message-input"
      a11ySuggestionsListLabel={'Suggested mentions'}
      forceSuggestionsAboveCursor={true}
      customSuggestionsContainer={customSuggestionContainer}
      style={mentionsInputStyle}
      {...properties}
      onChange={handleChange}
      value={value}
    >
      <Mention
        data={mentions}
        trigger="@"
        renderSuggestion={UserSuggestion}
        displayTransform={displayTransform}
        style={mentionStyle}
      />
    </MentionsInput>
  )
}

TextAreaWithMentions.displayName = 'InputWithMentions'
