import { createFileRoute } from '@tanstack/react-router'
import { FC } from 'react'

import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import { BreadcrumbsForwardSlash } from '@/components/layout/BreadcrumbsForwardSlash'
import { Api } from '@/contexts/ApiProvider'
import { useNewTaskContext } from '@/contexts/NewTaskProvider'
import { TaskBreadcrumb } from '@/features/task/components/TaskBreadcrumb'
import { createUsersQueryOptions } from '@/features/task/hooks/useUsers'
import { withBreadcrumbIconStyles } from '@/hoc/withBreadcrumbIconStyles'
import { createViewerQueryOptions } from '@/lib/hooks/useViewer'
import { cn } from '@/lib/utils'

export interface AllWorkNewSearch {
  assignToMe?: boolean
}

export interface AllWorkLoaderData {
  users: Awaited<ReturnType<Api['fetchAllUsers']>>
  viewer: Awaited<ReturnType<Api['fetchViewer']>>
}

export const Route = createFileRoute('/_private/new-task')({
  loader: async ({ context }): Promise<AllWorkLoaderData> => ({
    users: await context.queryClient.ensureQueryData(
      createUsersQueryOptions(context)
    ),
    viewer: await context.queryClient.ensureQueryData(
      createViewerQueryOptions(context)
    ),
  }),
  validateSearch: (search: Record<string, unknown>): AllWorkNewSearch => {
    if (search.assignToMe === true) {
      return {
        assignToMe: true,
      }
    }

    return {}
  },
})

const PlusMathIconWithBreadcrumbIconStyles =
  withBreadcrumbIconStyles(PlusMathIcon)

const TaskDetailBreadcrumb: FC = () => {
  const { task } = useNewTaskContext()

  const newTaskTitle = 'New Task'
  const hasTitle = !!task.title
  const title: string = (hasTitle ? task.title : newTaskTitle) ?? ''

  return (
    <>
      <PlusMathIconWithBreadcrumbIconStyles className="opacity-50" />
      <BreadcrumbsForwardSlash />
      <TaskBreadcrumb.Title
        Icon={TaskCircularCheckboxEmptyIcon}
        className={cn(
          'min-w-28 border border-dashed border-film-stronger',
          hasTitle ? 'opacity-100' : ''
        )}
        title={title}
      />
    </>
  )
}
TaskDetailBreadcrumb.displayName = 'TaskDetailBreadcrumb'

Route.interloom = {
  Breadcrumb: TaskDetailBreadcrumb,
}
